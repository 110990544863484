h1 {
    color: $default_text_color;
    color: #2a2b2f;
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
}

h2 {
    color: $default_text_color;
    font-weight: 500;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.3px;
    margin-bottom: 3px;
}

p {
    color: $light_text_color;
    font-style: 400;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.3px;
    margin-bottom: 15px;
}

small {
    color: #9EA0A5;
    font-size: .875em;
    font-weight: 400;
}