@use "../variables" as var;

.wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
}

.stats {
    font-size: 14px;
    line-height: 26px;

    > div {
        margin-bottom: 7px;
    }

    label {
        display: block;
        font-weight: 600;
        letter-spacing: 1px;
    }

    span {
        display: block;
    }
}