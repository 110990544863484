@use "../variables" as var;

.slider {
    position: relative;

    .slides {
        align-items: center;

        .slide {            
            img {
                width: 100%;
            }
        }
    }

    .arrows {
        > button {
            font-family: bootstrap-icons !important;
            color: #b5b5b5;
            font-size: 35px;
            font-weight: 300;
            line-height: 35px;
    
            padding: 0;
            border: 0;
            box-shadow: none;
        }

        .left {
            left: 1rem;

            &::after {
                display: inline-block;
                content: '\F284';
            }
        }

        .right {
            right: 1rem;

            &::after {
                display: inline-block;
                content: '\F285';
            }
        }
    }
}

@media (max-width: var.$breakpoint-tablet) and (min-width: var.$breakpoint-phone) {
    .slider {
        .arrows {
            > button {
                font-size: 25px;
                line-height: 25px;
            }
        }
    }
}