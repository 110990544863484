@use "../variables" as var;

.item {
    padding: 40px 0 0 0;

    hr {
        margin-top: 60px;
        margin-bottom: 20px;
    }

    a {
        text-decoration: none !important;
    }

    .image {
        overflow: hidden;

        min-height: 300px;
        max-height: 300px;
        
        img {
            width: 100%;
        }
    }

    .info {
        position: relative;
        min-height: 300px;
        padding: 30px 0 85px 40px;

        .title {
            font-size: 24px;
            font-weight: 300;
            line-height: 32px;
            letter-spacing: 1px;

            margin-bottom: 20px;

            transition: color 0.35s ease-in-out;
        }

        .description {
            font-size: 14px;
            font-weight: 300;
            line-height: 22px;
            letter-spacing: 1px;
        }

        .button {
            position: absolute;
            bottom: 30px;
            right: 0;

            font-size: 16px;
            font-weight: 300;
            line-height: 28px;

            transition: color 0.35s ease-in-out;

            &::before {
                content: "/";
                color: #df9100;
                font-weight: 600;
                margin-right: 3px;
            }
            
            &::after {
                display: inline-block;
                font-family: bootstrap-icons !important;
                content: '\F138';
                color: #df9100;
                font-size: 14px;
                font-weight: 300;

                margin-left: 8px;
                line-height: 28px;
            }
        }

    }
}

@media (max-width: var.$breakpoint-tablet) {
    .item {
        .info {
            .button {
                bottom: 0;
            }
        }
    }
}

@media (min-width: var.$breakpoint-tablet) {
    .item {
        .info {
            &:global(.order-lg-first) {
                padding: 30px 40px 60px 0;
    
                .button {
                    right: 40px;
                }
            }
        }
    }
}

@media (max-width: var.$breakpoint-phone) {
    .item {
        .info {
            padding: 30px 15px 70px 15px !important;
            min-height: auto;

            .button {
                right: 15px;
            }
        }
    }
}