@use "../variables" as var;

.header {
    height: 300px;
    
    overflow: hidden;
    position: relative;

    &:before {
        content: " ";
        position: absolute;
        top: -20px;
        right: -20px;
        bottom: 0;
        left: -20px;
        z-index: 0;

        background-image: url('/assets/img/header_background.png');
        background-attachment: fixed;
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
        filter: blur(4px);
    }

    .text {
        position: absolute;
        z-index: 1;
        bottom: 40px;

         h1 {
            display: inline-block;
            margin: 0;
            font-size: 40px;
            line-height: 60px;
            font-weight: 300;
            color: #ffffff;
            opacity: 1;
            visibility: visible;
            -webkit-transition: opacity 0.24s ease-in-out;
            -moz-transition: opacity 0.24s ease-in-out;
            transition: opacity 0.24s ease-in-out;
        }

        .breadcrumbs {
            display: inline-block;

            ul {
                text-align: left;
                margin-top: 0 !important;
                margin: 40px 0 0;
                font-size: 40px;
                line-height: 60px;
                list-style: none;
                padding: 0;

                
                li {
                    color: #ffffff;
                    opacity: 0.6;
                    display: inline-block;

                    > :after {
                        content: "//";
                        color: #df9100;
                        margin: 0 10px;
                    }

                    &:first-of-type {
                        padding-left: 0;
                    }

                    &:last-of-type {
                        padding-right: 0;
                        opacity: 1;
                    }

                    &:before {
                        color: #ffffff;
                        opacity: 0.6;
                        content: '/';
                        display: inline-block;
                        margin-left: 7px;
                        margin-right: 7px;
                    }

                    &:first-child:before {
                        display: none;
                    }

                    a {
                        color: #ffffff;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

@media (max-width: var.$breakpoint-phone) {
    .header {
        &:before {
            background-size: auto 300px;
        }
    }
  }