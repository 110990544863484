@use "../variables" as var;

.navbar {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  padding: 0 !important;

  transition: all 0.35s ease-in-out;

  --bs-navbar-active-color: #FFFFFF !important;

    &:global(.dark) {
        background-color: #2a2b2f;

        .logo {
            line-height: 70px;
        }
    }
    

  > div {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .toggler {
    position: relative;
    width: 30px;
    height: 26px;
    padding: 0;
    border: 0;
    outline: none;
    box-shadow: none !important;

    .icon_bar:nth-of-type(1) {
      top: 0;
    }

    .icon_bar:nth-of-type(2) {
      top: 12px;
    }
    
    .icon_bar:nth-of-type(3) {
      top: 24px;
    }
    
    .icon_bar {
      position: absolute;
      background-color: #FFFFFF;
      display: block;
      width: 30px;
      height: 2px;
      border-radius: 1px;
      transition: all 500ms ease-in-out;
    }
  
    &:not(:global(.collapsed)) .icon_bar:nth-of-type(1) {
      width: 38px;
      margin-top: 13px;
      margin-left: -4px;
      transform: rotate(45deg);
    }
    
    &:not(:global(.collapsed)) .icon_bar:nth-of-type(2) {
      background-color: transparent;
    }
    
    &:not(:global(.collapsed)) .icon_bar:nth-of-type(3) {
      width: 38px;
      top: 13px;
      margin-left: -4px;
      transform: rotate(-45deg);
    }
  }

  .logo {
    &:before {
      content: "<";

      font-size: 20px;
      color: #df9100;

      margin-right: 1px;
    }

    &:after {
      content: "/>";

      font-size: 20px;
      color: #df9100;

      margin-left: 1px;
    }

    color: #FFFFFF !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 93px;
    padding: 0;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: line-height 0.4s ease 0s;
  }

  .link {
    position: relative;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    margin: 17px 12px;
    padding: 7px 3px 5px !important;
    letter-spacing: 1px;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease 0s;

    &:before {
      position: absolute;
      top: 6px;
      left: -10px;
      content: ">";
      color: transparent;
      font-weight: 600;
      margin-right: 3px;
      transition: color 0.3s ease 0s;
    }

    &:hover,
    &:active {
      color: #FFFFFF;
      border-color: #FFFFFF !important;

      &:before {
        color: #df9100;
      }
    }

    &:global(.active) {
        border-color: #FFFFFF !important;
        
        &:before {
          color: #df9100;
        }
    }
  }
}

@media (max-width: var.$breakpoint-tablet) {
  .navbar {
      .navigation {
        margin: 20px 0 !important;

        .link {
          &:hover,
          &:active {
            border-color: transparent !important;
          }

          &:global(.active) {
            font-weight: 500;
            border-color: transparent !important;
          }
        }
    }
  }
}


@media (max-width: var.$breakpoint-phone) {
  .navbar {
    > div {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }
}