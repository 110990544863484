$main_color: #2a2b2f;
$main_color_hover: #df9100;
$main_color_active: #c17e00;
$main_color_disabled: #634000;
$main_light_color: #acacac;

$default_text_color: #2a2b2f;
$light_text_color: #6b6d6f;

$breakpoint-phone: 767.5px;
$breakpoint-tablet: 991.5px;
$breakpoint-small-screen: 1199.5px;